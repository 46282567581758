import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { ExperienceCard as ExperienceCardSharedUI } from "@shared-ui/travel-content-editorial";
import { ExperienceCardProps } from "./typings";
import { mapAnalyticsData } from "components/utility/AnalyticsData";
import { usePreviewAndRequestDateInputs } from "utils/hooks/usePreviewAndRequestDateInputs";

const ExperienceCard = withStores(
  "uriContext",
  "context"
)(
  observer((props: ExperienceCardProps) => {
    const { context, templateComponent, uriContext: uriContextStore } = props;

    const uriContext = uriContextStore?.get();

    const { previewParam, requestDateParam } = usePreviewAndRequestDateInputs();
    const { contentTopic, style: view } = templateComponent.config;
    const analyticsData = mapAnalyticsData(context, templateComponent);

    const inputs = {
      uriContext: uriContext as React.ComponentProps<typeof ExperienceCardSharedUI>["inputs"]["uriContext"],
      contentTopic,
      experienceType: "experienceCard",
      preview: previewParam,
      requestDate: requestDateParam,
    };
    return <ExperienceCardSharedUI inputs={inputs} view={view} analyticsData={analyticsData} />;
  })
);

export default ExperienceCard;
